<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="12">
        <h1 class="mt-10  ">Nuevo Contrato</h1>
        <hr class="">
      </v-col>
      <v-form ref="form" class="mt-10" @submit.prevent="submitForm">
        <v-row class="mb-4">
          <v-expansion-panels inset>
            <v-expansion-panel
            >
              <v-expansion-panel-header>Texto de Contrato</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12">
                  <h1 class="mt-4 mx-2 ">Texto de Contrato</h1>
                  <trumbowyg
                      v-model="data.contrato_texto"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Texto de Contrato"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
            >
              <v-expansion-panel-header>Declaraciones</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12">
                  <h1 class="mt-4 mx-2 ">Declaraciones
                  </h1>
                  <trumbowyg
                      v-model="data.declaraciones"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Declaraciones"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
            >
              <v-expansion-panel-header>Cláusulas</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12">
                  <h1 class="mt-4 mx-2 ">Cláusulas
                  </h1>
                  <trumbowyg
                      v-model="data.clausulas"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Cláusulas"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
            >
              <v-expansion-panel-header>Vigésima Tercera</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12">
                  <h1 class="mt-4 mx-2 ">Vigésima Tercera
                  </h1>
                  <trumbowyg
                      v-model="data.vigesima_tercera"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Vigésima Tercera"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
            >
              <v-expansion-panel-header>Fecha y Hora</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12">
                  <h1 class="mt-4 mx-2 ">Fecha y Hora
                  </h1>
                  <trumbowyg
                      v-model="data.fecha_hora"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Fecha y Hora"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
            >
              <v-expansion-panel-header>Pie de Licitante</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12">
                  <h1 class="mt-4 mx-2 ">Pie de Licitante
                  </h1>
                  <trumbowyg
                      v-model="data.pie_licitante"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Pie de Licitante"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
            
          </v-expansion-panels>
          <v-col cols="12" class="mt-4">
            <v-btn type="submit" color="primary" style="width: 200px">
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import Overlay from "../../components/Overlay";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload'
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64'
export default {
  name: "Tenders",
  data() {
    return {
      overlay: false,
      configs: {
        advanced: {
          autogrow: true,
          // Adding color plugin button
          btnsAdd: ['foreColor', 'backColor'],
          btnsDef: {
            // Create a new dropdown
            'btnGrp-image': {
              dropdown: ['base64'],
              ico: 'insertImage'
            }
          },
          // Limit toolbar buttons
          btns: [
            ['viewHTML'],
            ['formatting'],
            'btnGrp-semantic',
            ['undo', 'redo'],
            ['superscript', 'subscript'],
            ['btnGrp-image'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['link'],
            ['btnGrp-justify'],
            ['btnGrp-lists'],
            ['horizontalRule'],
            ['foreColor'], ['backColor'],
            ['removeformat'],
            ['fullscreen'],
            [['bold', 'italic'], ['link']]
          ]
        },
      },
      data: {
        tenderId: null,
        formatId: null,
        contrato_texto: "",
        vigesima_tercera: "",
        declaraciones: "",
        clausulas: '',
        fecha_hora: '',
        pie_licitante: '',
      },
    };
  },
  components: {
    Overlay,
    Trumbowyg
  },
  created() {
    this.data.tenderId = this.$route.params.id;
    this.data.formatId = this.$route.params.formatId;

  },
  methods: {
    ...mapActions('contract', ['createContract']),
    async validateRequired(){
      if (!this.data.contrato_texto){
        this.sweetAlertWarning('Campo Texto de Contrato es necesario');
        return false
      }
      if (!this.data.declaraciones){
        this.sweetAlertWarning('Campo Declaraciones es requerido');
        return false
      }
      if (!this.data.clausulas){
        this.sweetAlertWarning('Campo Clausulas es requerido');
        return false
      }
      if (!this.data.vigesima_tercera){
        this.sweetAlertWarning('Campo Vigeisima Tercera es requerido');
        return false
      }
      if (!this.data.fecha_hora){
        this.sweetAlertWarning('Campo Fecha y hora es requerido');
        return false
      }
      if (!this.data.pie_licitante){
        this.sweetAlertWarning('Campo Pie Licitante es requerido');
        return false
      }
      return true

    },
    async submitForm() {
      const validateData = await this.validateRequired();

      if (!validateData){
        return
      }
      this.overlay = true;
      const response = await this.createContract(this.data);
      if (!response.error){
        this.sweetAlertSuccess('Se creo correctamente')
        await this.$router.push(`/licitaciones_documentos/${this.data.tenderId}`);
      }
      else{
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
